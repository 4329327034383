.banner-button {
    background-color: #DAA520 !important;
    border: none !important; 
    font-weight: bold !important; 
    margin-top: 30px !important;
    display: block; 
    text-align: left !important; 
}

.banner-container-salon {
    background-color: #FAFAFA;
    width: 100vw; 
    height: 363px; 
    width: fit-content;
 
}

.salon-banner-img {
    height: 400px; 
   
   
}

div.col.container-text.text-start {
    margin: 0 !important;
    padding: 0 !important;
}

.div-text {
    margin-left: 20px;
}




