.cards-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 70px;
    text-align: center;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #ccc;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  
  .description-text {

    text-align: left;
}



 
  