
.CompanyPlans {
    display: inline-flex;
    width: 700px;
    border-bottom: solid 1px #D9D9D9;
    margin-top: 20px;
    align-items: flex-start;
}

.listCompanyPlans {
    display: inline-flex;
    border-bottom: solid 1px #D9D9D9;
    width: 100%;
    align-items: flex-start;
}

.divListCompanyPlans {
    display: inline-flex;
    width: 80%;
    margin-top: 10px;
}
.dataPlans {
    margin-left: 20px;
    margin-right: 06px;
    font-weight: bold;
  
}

.button4 {
    margin-top: 0px !important;
    font-size: 15px;
    color: #DA2023;
    background-color: white;
    border-radius: 10px;
    border: solid 1px #DA2023;
    text-align: center;
}
.divButton4{
    margin-left: 20px;
    display: inline-flex;
    width: 20%;
}