.nav-link {
    margin-right: 20px; 
    color: #212529 !important;
  }

  .custom-navbar {
    background-color: white !important;

  }


.user-name {
  font-size: 1rem; 
  margin-right: 10px; 
  font-weight: bold;
}

.logout-btn {
  font-size: 0.9rem; 
  padding: 6px 12px; 
  background-color: white !important; 
  color: #DAA520 !important; 
  font-weight: bold !important; 
  outline: none !important; 
  border: none !important; 
  box-shadow: none !important; 
  border-radius: 5px; 
  cursor: pointer; 
  border-color: #DAA520 !important;
  margin-right: 20px;
}


