
.button-group {
    display: flex;
    gap: 30px; 
    margin-left: 30px;
}

.plan-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}

.btn-adm-yellow {
    border-radius: 5px !important;
    border: 2px solid !important;
    border-color: #DAA520 !important;
    background-color: white !important;
    color: #DAA520 !important;
    cursor: pointer;
    width: 150px;
    font-weight: bold !important;
}

.btn-adm-red {
    border-radius: 5px !important;
    border: 2px solid !important;
    border-color: #DA2023 !important;
    background-color: white !important;
    color: #DA2023 !important;
    cursor: pointer;
    width: 150px;
    font-weight: bold !important;
}

.plan-name {
    font-weight: bold;
}

.create-btn {
    border-radius: 5px !important;
    background-color: #DAA520 !important;
    color: white !important;
    cursor: pointer;
    width: 150px;
    font-weight: bold !important;
    border: none !important;
}

.hr {
    border: none; 
    border-top: 1px solid #ccc; 
    width: 50%; 
    margin: 40px auto; 
}
