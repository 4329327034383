.pricing-card {
    width: 250px;
    border-radius: 10px !important;
    text-align: center; 
    padding: 20px; 
    border: none !important; 
  }
  
  .card-title {
    font-size: 25px !important; 
    color: white !important; 
    margin-bottom: 10px;
    font-weight: bold !important; 
  }
  
  .card-text {
    font-size: 24px; 
    font-weight: bold; 
    color: white; 
    margin-bottom: 20px; 
  }

  .card-price-small {
    font-size: 18px; 
    color: white; 
    font-weight: bold !important; 
  }
  
  .card-btn {
    background-color: white !important; 
    color: black !important; 
    border: none !important; 
    border-radius: 10px !important; 
    font-weight: bold !important; 
    padding: 10px 20px;
    cursor: pointer; 
    font-size: 16px; 
    width: 160px;
  }
  
  .card-btn:hover {
    background-color: pink ; 
  }
  