.main-container {
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    align-items: start;
    text-align: left;
}

.main-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.main-container h2 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
}

.main-container .service-selection {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 100px;
    
}

.main-container .service-selection .service-item {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    font-size: 18px;
}

.main-container .slot-selection {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.main-container .slot-selection .service-slots {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.main-container .slot-selection .service-slots .slot-time {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.main-container .slot-selection .service-slots .slot-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
}

.main-container .schedule-btn {
    height: 3rem;
    text-align: center;
    border-radius: 10px;
    align-self: end;
}

.line {
    border: none;
    border-top: 1px solid #212529;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}