.login-container {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    margin: 0; /* Remove qualquer margem */
    padding: 0; /* Remove qualquer padding */
}

.form-container {
    width: 40%; /* Ajuste a largura do formulário */
    height: 100%; /* Permite que a altura se ajuste ao conteúdo */
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 0 auto; /* Centraliza o formulário na página */
    display: flex;
    flex-direction: column; /* Organiza os elementos em coluna */
    align-items: center; /* Centraliza horizontalmente os itens */
}

.image-container {
    width: 60%; /* Ajuste a largura da imagem */
    height: 100vh; /* Garante que a imagem ocupe 100% da altura */
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

h2 {
    margin-bottom: 20px;
    margin-top: 20px; /* Ajuste a margem superior conforme necessário */
}

.input-login {
    padding: 12px; /* Mantém o padding interno */
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%; /* Faz com que o campo ocupe 100% do contêiner */
    max-width: 500px; /* Aumenta a largura máxima para 500px */
    box-sizing: border-box; /* Garante que o padding não aumente a largura total */
}

.login-btn {
    margin-top: 20px !important;
    padding: 10px;
    background-color: #d39e00 !important;
    border-radius: 5px !important;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
}



.success-message {
    color: green; /* Cor do texto */
    margin-top: 20px; /* Espaçamento acima da mensagem */
    font-size: 1.2rem; /* Tamanho da fonte */
    text-align: center; /* Centraliza a mensagem */
}


.forgot-password {
    margin-top: 20px; /* Adiciona um espaço acima do link */
    text-align: center; /* Centraliza o texto */
    display: block; /* Faz com que o link ocupe toda a linha */
    color: black; /* Define a cor do texto como preto */
    text-decoration: none; /* Remove o sublinhado padrão */
}

.forgot-password:hover {
    text-decoration: underline; /* Adiciona um sublinhado ao passar o mouse, se desejar */
}

/* Media query para telas menores */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .form-container,
    .image-container {
        width: 100%; /* Ambos os elementos ocupam 100% da largura em telas menores */
    }
}
