.listComanyManagement {
    display: inline-flex;
    border-bottom: solid 1px #D9D9D9;
    margin-top: 10px;
    align-items: flex-start;
}

.button3 {
    width: 100px;
    height: 26px;
    font-size: 15px;
    color: #DA2023;
    background-color: white;
    border-radius: 10px;
    border: solid 1px #DA2023;
    margin-left: 20px;
    align-items: center;
}

.dataManagement {
    margin-left: 20px;
    margin-right: 06px;
    font-weight: bold;
}

