
  .images-container {
    margin-bottom: 35px;
  }

  .title-services{
    text-align: left;
  }

  p.see-more {
    margin: 0; 
    text-align: end;  
    margin-top: -70px;
    margin-bottom: 50px;
    cursor: pointer;
   
  }


 
  
