.footer-container {
    background-color: #333333 !important;
    width: 100% !important;           
    color: #fff;         
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    padding: 20px;
    margin-top: 65px;
    height: 210px;
}

.text-container {
    flex: 1;
    padding-top: 20px;
    font-size: 14px;
}

.social-icons {
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
    margin-right: 30px;
   
}

.social-icons a {
    color: #fff; 
    font-size: 15px; 
    margin: 5px 0; 
    text-decoration: none; 
    transition: color 0.3s;
}

