.plan-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }

  .custom-select-size {
    width: 220px !important; 
    padding: 5px; 
    min-width: 220px; 
    max-width: 220px; 
    color:  #a9a9a9 !important;

  }

  .custom-btn {
    background-color: #DAA520 !important;
    color: white;
    border: none !important;
    font-weight: bold !important;
  }

  .divider {
    border: none; 
    border-top: 1px solid #ccc; 
    width: 84% !important; 
    margin: auto; 
}

.price{
  width: 20%; 
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc; 
  border-radius: 5px;
}

.form-payment{
  margin-top: 50px !important;
}


