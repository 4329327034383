.cancel-button {
    border: 2px solid #DAA520;
    background-color: transparent;
    color: #DAA520;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s, color 0.3s;
    width: 10rem;
    height: 2.5rem;
    text-transform: none;
}

.save-button {
    border: 2px solid #DAA520;
    background-color: #DAA520;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s, color 0.3s;
    width: 10rem;
    height: 2.5rem;
    text-transform: none;
}

.modal-rating {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24;
}