.listServiceManagement {
    width: 70%;
    border-bottom: solid 1px #D9D9D9;
    margin-top: 10px;
    display: inline-flex;
    align-items: baseline;
}

.button7 {
    font-size: 15px;
    color: #DA2023;
    background-color: white;
    border-radius: 10px;
    border: solid 1px #DA2023;
    margin-left: 20px;
    align-items: center;
}

.dataServiceManagement {
    width: 30%;   
    text-align: left;
}

.dataServiceManagementLast{
    width: 10%;   
    align-items: baseline;
    text-align: left;

}

.serviceManagement {
    width: 30%;
    font-weight: bold;
    font-size: 90%;
}

.serviceManagementLast {
    width: 15%;
    font-weight: bold;
    font-size: 90%;
}
.buttonRemoveService{
    width: 8%;
}