.pricing-card {
    transition: transform 0.2s; 
  }
  
  .pricing-card:hover {
    transform: scale(1.05); 
  }

 
  .custom-margin {
    display: grid;
    grid-template-columns: 1fr; 
    gap: 10px;
    margin: 10px;
  }
  
  
 
  @media (min-width: 992px) {
    .custom-margin {
      grid-template-columns: repeat(3, 1fr); 
      margin: 300px; 
      margin-top: 60px !important;
      margin-bottom: 40px !important;
    }
  }
  
  