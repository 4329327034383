.h2 {
    font-size: 15px;
    color: #333333;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form {
    margin-top: 70px;
    display: grid
}

.label {
    color: #333333;
    font-size: 15.4px;
    width: 272px;
    text-align: left;
}

.input {

    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 5PX;
}

.cadastro_salao {
    width: 100%;
    display: inline-flex;
}

.formulario {
    width: 40%;
    margin-left: 10%;
    display: inline-flex;
}

.img {
    width: 50%;
    height: 100%;
}

.img_cadastro_salao {
    width: 100%;
    height: 700px;
}

.button {
    width: 272px;
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: #dda520;
    border-radius: 5px;
    border: none;
    margin-top: 30px;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .imgApp {
    width: 100px;
    height: 100px;
    display: inline-table;
    align-items: center;
    justify-content: center;
    background: #e9f3fe;
  }
  .parent {
    width: 300px;
    margin: 01px;
    padding: 20px;
    background: #ffffff;
    border-radius: 25px;
    box-shadow: 7px 20px 20px rgb(210, 227, 244);   
  }
  .file-upload {
    text-align: center;
    border: 3px dashed #f7d483;
    padding: 10px;
    position: relative;
    cursor: pointer;
  }
  .file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
  }
  .file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
 .img-upload{
width: 20px;
  }