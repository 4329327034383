.img-banner {
    width: 350px !important;
    margin-top: 60px;
    margin-right: 60px;
}

.banner-title {
    color: #DAA520;
    font-weight: bold;

}

.banner-button {
    background-color: #DAA520 !important;
    border: none !important; 
    font-weight: bold !important; 
    margin-top: 30px !important;
}

.text-title{
    margin: 0;

}

.banner-container {
    background-color: #FAFAFA;
    max-width: 100% !important;
}

.container-text {
    margin-left: -15px;
}

.container.banner-container {
    padding: 0; 
  }