.user-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.user-info .user-plan {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.user-info .plan-btn-group {
  gap: 30px;
}

.user-info .plan-btn-group .plan-btn-yellow {
  border-radius: 5px;
  border: 2px solid;
  border-color: #daa520;
  background-color: white;
  color: #daa520;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}

.user-info .plan-btn-group .plan-btn-red {
  border-radius: 5px;
  border: 2px solid;
  border-color: #da2023;
  background-color: white;
  color: #da2023;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}

.user-info .line {
  margin-top: 40px;
  width: 60%;
}

.schedules {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.schedules .schedule-item {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  align-items: center;
  gap: 30px;
}

.schedules .schedule-item .schedule-btn-yellow {
  border-radius: 5px;
  border: 2px solid;
  border-color: #daa520;
  background-color: white;
  color: #daa520;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}

.schedules .schedule-item .schedule-btn-red {
  border-radius: 5px;
  border: 2px solid;
  border-color: #da2023;
  background-color: white;
  color: #da2023;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}
