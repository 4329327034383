.plan-container {
    display: flex; 
    align-items: center; 
    justify-content: center;
    
}


.plan-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}



.plan-name {
    font-weight: bold;
}

.create-btn {
    border-radius: 5px !important;
    background-color: #DAA520 !important;
    color: white !important;
    cursor: pointer;
    width: 150px;
    font-weight: bold !important;
    border: none !important;
}

.alert-danger {
    width: 25%;
    margin:auto;
}



