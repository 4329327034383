.addServices {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    margin-left: 15px;
    color: #1f1e1e;
}

.serviceRegistration {
    width: 70%;
    display: inline-flex;
    margin-top: 20px;
}

.registerServices {
    border-right: #941b1b;
    border-radius: 8px;
    width: 40%;
    margin-top: 40px;
    border: solid 1px rgba(208, 210, 212, 0.76);
    border-radius: 8px;
    display: inline-flex;
}

.registeredServices {
    margin-top: 50px;
    margin-left: 40px;
    border-bottom: solid 1px rgba(208, 210, 212, 0.76);
    display: inline-block;
}

.inputServiceRegistration {
    cursor: auto;
    border: 1px solid rgba(208, 210, 212, 0.76);
    border-radius: 5px;
    display: block;
    width: 400px;
    margin-left: 20px;
    height: 40px;
}

.labelRegisterServices {
    width: 300px;
    display: flex;
    margin-left: 30px;
    margin-top: 20px;
}

.labelServiceRegistration {
    width: 300px;
    display: flex;
    margin-top: 3px;
    margin-left: 30px;
}

.inputRegisterServices {
    font-size: 10px;
}

.labelCalendar {
    width: 300px;
    font-weight: bold;
    margin-top: 20px;
    display: flow-root;
}



.react-calendar__navigation button {
    color: rgba(128, 130, 133, 0.76);
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    text-align: center;
    font: inherit;
    font-size: 0.833em;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: rgb(173, 168, 168);
    border: 1px solid rgba(208, 210, 212, 0.76);
    display: inline-block;
    font-family: 'Arial', 'Helvetica', sans-serif;
    line-height: 1.125em;

}

.rmdp-input {
    border: 1px solid rgba(208, 210, 212, 0.76);
    border-radius: 5px;
    width: 102px;
    margin: 1px 0;
    padding: 2px 5px;
    display: block;
    margin-top: 10px;
}

.button5 {
    width: 134px;
    font-size: 15px;
    color: white;
    background-color: #dda520;
    border-radius: 08px;
    border: solid 1px #dda520;
    margin-top: 30px;
    margin-bottom: 45px;
    display: inline-block;
}

.button6 {
    width: 134px;
    font-size: 15px;
    color: #DA2023;
    background-color: #fcfcfc;
    border-radius: 08px;
    border: solid 1px #DA2023;
    margin-top: 30px;
    display: inline-block;
}

.labelServiceRegistrationHora {
    display: inline;
    font-weight: bold;
    font-size: 15px;
    margin-right: 10px;
}

.react-calendar__month-view__days__day {
    color: black;
}

.openingHours {
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
    justify-content: space-around;
    flex-direction: row;
}


.react-ios-time-picker-main {
    width: 150px;
}

.hours {
    text-align: center;
    float: right;
}
.hours1 {
    margin-left: 80px;
}