/* Estilo geral da página */
.user-registration-container {
    display: flex;
    height: 100vh;
    justify-content: space-between; /* Mantém a imagem e o formulário separados */
    align-items: center; /* Centraliza verticalmente */
    background-color: #f4f4f4; /* Cor de fundo */
    margin: 0; /* Remove qualquer margem */
    padding: 0; /* Remove qualquer padding */
}

/* Estilo do formulário */
.form-container {
    width: 40%; /* Ajuste a largura do formulário */
    height: auto; /* Permite que a altura se ajuste ao conteúdo */
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 0 auto; /* Centraliza o formulário na página */
    display: flex;
    flex-direction: column; /* Organiza os elementos em coluna */
    align-items: center; /* Centraliza horizontalmente os itens */
    max-width: 500px; /* Limita a largura máxima do formulário */
}

/* Estilo do título */
h2 {
    margin-bottom: 20px;
    margin-top: 20px; /* Ajuste a margem superior conforme necessário */
}

/* Estilo da imagem */
.image-container {
    width: 60%; /* Ajuste a largura da imagem */
    height: 100vh; /* Garante que a imagem ocupe 100% da altura */
    overflow: hidden;
    position: relative; /* Permite que o conteúdo seja posicionado dentro */
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Mantém a proporção da imagem */
    object-position: center; /* Centraliza a imagem dentro do container */
}

/* Estilo dos campos de entrada */
.input-login {
    padding: 12px; /* Mantém o padding interno */
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%; /* Faz com que o campo ocupe 100% do contêiner */
    max-width: 500px; /* Aumenta a largura máxima para 500px */
    box-sizing: border-box; /* Garante que o padding não aumente a largura total */
}

/* Estilo do botão */
.button-cadastro {
    padding: 10px;
    background-color: #ffc107;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

button:hover {
    background-color: #d39e00;
}

/* Estilo da mensagem de sucesso */
.success-message {
    color: green; /* Cor do texto */
    margin-top: 20px; /* Espaçamento acima da mensagem */
    font-size: 1.2rem; /* Tamanho da fonte */
    text-align: center; /* Centraliza a mensagem */
}

/* Estilo do link "Esqueceu a senha?" */
.forgot-password {
    margin-top: 20px; /* Adiciona um espaço acima do link */
    text-align: center; /* Centraliza o texto */
    display: block; /* Faz com que o link ocupe toda a linha */
    color: black; /* Define a cor do texto como preto */
    text-decoration: none; /* Remove o sublinhado padrão */
}

.forgot-password:hover {
    text-decoration: underline; /* Adiciona um sublinhado ao passar o mouse */
}

/* Controle de visibilidade da senha */
.password-container {
    display: flex;
    align-items: center; /* Centraliza verticalmente */
    width: 100%; /* Para ocupar toda a largura do formulário */
    max-width: 500px; /* Limite de largura */
}

.password-container input {
    flex: 1; /* Faz com que o campo de senha ocupe o espaço restante */
    margin-bottom: 20px;
    padding: 12px; /* Mantém o padding interno */
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

/* Estilo do botão de mostrar/ocultar senha */
.toggle-password {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: #007bff;
}

.toggle-password i {
    font-size: 1.2rem;
}

/* Estilo do botão de voltar */
.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 20px;
    left: 40px;
    z-index: 100; /* Garante que a seta fique acima dos outros elementos */
    color: #d3d3d3; /* Cor da seta */
}

/* Media query para telas menores */
@media (max-width: 768px) {
    .user-registration-container {
        flex-direction: column; /* Organiza os elementos em coluna em telas menores */
    }

    .form-container,
    .image-container {
        width: 100%; /* Ambos os elementos ocupam 100% da largura em telas menores */
    }

    /* Ajustes no botão de voltar para telas menores */
    .back-button {
        font-size: 20px; /* Reduz o tamanho da seta */
        top: 10px; /* Ajusta a posição */
        left: 10px; /* Ajusta a posição */
    }
}

/* Media query para telas maiores */
@media (min-width: 769px) {
    .user-registration-container {
        flex-direction: row; /* Garante que os elementos sejam lado a lado em telas maiores */
    }

    .form-container {
        height: auto; /* Permite que a altura do formulário se ajuste ao conteúdo */
        margin-left: 30px; /* Espaçamento à esquerda do formulário */
    }

    .image-container {
        height: 100vh; /* Garante que a imagem ocupe 100% da altura */
    }
}
