.main-container1 {
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 80px;
    align-items: start;
    text-align: left;
}

.main-container1 h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.main-container1 h2 {
    font-size: 18px;
    font-weight: normal;
}

.main-container1 p {
    font-size: 18px;
    text-align: left;
}

.main-container1 .icon {
    margin-right: 0.5rem;
}

.main-container1 .salon-details {
    display: flex;
    flex-direction: column;
}

.main-container1 .salon-details .salon-img {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Corta a imagem se necessário para manter a proporção */
    margin-bottom: 10px;
}

.main-container1 .service-list .service-item {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
}

.main-container1 .service-list .service-item .schedule-btn {
    height: 3rem;
    text-align: center;
    border-radius: 10px;
}

.line1 {
    border: none;
    border-top: 1px solid #212529;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
  }

