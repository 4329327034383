.button1 {
    width: 100px;
    font-size: 15px;
    color: #dda520;
    background-color: white;
    border-radius: 10px;
    border: solid 1px #dda520;
    margin-left: 150px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.button2 {
    width: 134px;
    height: 26px;
    font-size: 15px;
    color: white;
    background-color: #dda520;
    border-radius: 08px;
    border: solid 1px #dda520;
    margin-top: 30px;
    margin-bottom: 15px;

}

.listCompanyArea {
    display: inline-block;
    border-bottom: solid 1px #D9D9D9;
    text-align: start;
}

.labelCompanyArea {
    color: #333333;
    font-size: 15.4px;
    width: 272px;
}

.companyarea {
    margin-top: 2%;
    display: inline-flex;
}

.formularioCompanyArea {
    width: 40%;
}

.CompanyArea1 {
    margin-top: 5%;
    width: 40%;
}
.inputCompanyArea{
    height: 40px;
    width: 400px;
    border: 1px solid #D9D9D9;
    border-radius: 5PX;
    color: #bbbaba;
    padding-left: 5px;
}